import { SYSTEM_TIME_ZONE } from "consts/timezone";
import moment from "moment-timezone";

export const capitalizeFirstLetter = (string: string = "") => {
    if(typeof string === 'string') {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string
};

export const setTimeWithTimeZone = (
    time?: string | Date | moment.Moment,
    timezone: string = SYSTEM_TIME_ZONE
) => {
    return moment(time).tz(timezone);
};

export const getTimeFilter = () => {
    // const startTimeTemp = setTimeWithTimeZone('2001-01-01');
    // startTimeTemp.set('hour', 0);
    // startTimeTemp.set('minutes', 0)
    // startTimeTemp.set('second',0)
    // const startTime = startTimeTemp.format();
    const startTime = '2000-01-01T00:00:00-05:00';
    const timeNow = setTimeWithTimeZone(moment());
    const endTime = timeNow;
    return {
        startTime,
        endTime
    }
}

export const getUrlVars = () => {
    let vars: any = {}
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m: string, key: string, value: string) {
        vars[key] = value
        return ''
      }
    )
    return vars
  }

  /**
   * 
   * @param object: Object
   * @param array: Array 
   * @param keyObj: key of Object
   * @returns object if object exists in array, false if object not exists in array
   */
export const checkObjectExistsInArray = (
    object: any,
    array: any,
    keyObj: any
) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i][keyObj] === object[keyObj]) {
            return object;
        }
    }
    return false;
};

  /**
   * @param array: Array 
   * @param keyObj: key of Object
   * @returns keyObj if object exists in array, false if object not exists in array
   */
export const checkExistKeyObject = (keyObj: any, array: any) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i][keyObj]) {
            return keyObj;
        }
    }
    return false;
};

  /**
   * @param array: Array 
   * @param valueKey: key value of object
   * @returns a Object in Array by value
   */
  export const getObjectInArrayByValueKey = (valueKey: any, array: Array<{value: any, label: any, id?: any}>) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i]['value']===valueKey) {
            return array[i];
        }
    }
    return false;
};
  
export const urlFormatReg = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
