import React from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import style from "./SelectAutoComplete.module.scss";
import { ExpandMore } from "@mui/icons-material";

interface SelectProps {
  options: Array<{ value: any; label: any }>;
  inputProps: { name: string };
  onChange?: Function;
  value?: any;
  placeholder?: string;
  label?: string;
  fullWidth?: any;
  size?: any;
  name?: string;
  multiple?: boolean;
}
const SelectAutoComplete = (props: SelectProps | any) => {
  const {
    options,
    inputProps,
    onChange,
    value,
    placeholder = "Select...",
    label,
    multiple,
    name,
    // size='small',
    ...rest
  } = props;
  const onChangeSelect = (event: any, value: any, reason: string) => {
    if (value !== null) {
      onChange && onChange(inputProps.name, value);
      return;
    }
    onChange && onChange(inputProps.name, "");
  };
  return (
    <div
      className={
        multiple
          ? `SelectAutoCompleteMultiWraper ${style["SelectAutoCompleteMultiWraper"]}`
          : `SelectAutoCompleteWraper ${style["SelectAutoCompleteWraper"]}`
      }
    >
      {label && (
        <Typography component="h6" variant="h6" className={style["label"]}>
          {label}
        </Typography>
      )}
      <Autocomplete
        {...rest}
        value={value}
        {...props}
        options={options}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              // size={size}
            />
          );
        }}
        onChange={onChangeSelect}
        popupIcon={<ExpandMore />}
      />
    </div>
  );
};

export default SelectAutoComplete;
